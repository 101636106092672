<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">其它管理体系的审核</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        景翔科技是一家致力于企业管理信息化的“咨询+IT”服务的科技企业。二十多年来，主要从事管理体系建设、质量管理、价值链建设与业务流程优化、零缺陷管理及数字化转型等咨询和培训服务。以下是我们公司主要的培训课程，分成三类：
        <br />
        管理课程：主要涉及企业中高层管理课程和个别专业课程
        <br />
        技能课程：企业管理的工具及方法
        <br />
        标准课程：质量、环境、安全管理体系的标准培训课程
        <br />
        另，为满足不同的系列培训需要，我们推出了“系列培训套餐
        <br />
        咨询电话：（020）39984322/13560068664
      </p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/76.png"
        class="SonImgW"
      />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/77.png"
        class="SonImgW"
      />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/78.png"
        class="SonImgW"
      />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/79.png"
        class="SonImgW"
      />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/80.png"
        class="SonImgW"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
</style>